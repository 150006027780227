import { GiftPatternWithProductFragmentFragment, SkuFragmentFragment } from "~/types/type.generated"
import { GiftPatternWithSku } from "~/types/cart"

// 在庫切れのギフトを取得
export const getOutOfStockGiftSku = (
  giftPattern: GiftPatternWithProductFragmentFragment,
): SkuFragmentFragment | undefined => {
  const giftProduct = giftPattern.product
  if (!giftProduct || !giftProduct.skus) return undefined
  return giftProduct.skus.find(
    (sku) => sku.stock_quantity === 0 || sku.stock_quantity < sku.stock_quantity_limit + sku.egift_stock_quantity_limit,
  )
}

/**
 * 選択されたSKUに基づいて、ギフトパターンに適した包装サイズを計算する
 * @param selectedSku - 選択中のSKU
 * @param giftPattern - 商品情報を含むギフトパターン
 * @returns 選択されたSKUのパッケージサイズに収まる最小の包装サイズを持つSKU、または適切なSKUが見つからない場合はundefinedを返します。
 */
export const getCalcWrappingSize = (
  selectedSku: SkuFragmentFragment | null,
  giftPattern: GiftPatternWithProductFragmentFragment,
): SkuFragmentFragment | undefined => {
  const giftProduct = giftPattern.product
  if (!giftProduct || !giftProduct.skus) return undefined
  if (!selectedSku) return undefined
  const packageSize = selectedSku.package_size
  const packableWrappingSku = giftProduct.skus.filter(
    (sku) => sku.maximum_wrapping_size >= packageSize && !isOutOfStock(sku),
  )
  if (packableWrappingSku && packableWrappingSku.length >= 1) {
    // packableWrappingSkuの配列内で、包装サイズの最小値の値を取得する
    const calcWrappingSize = Math.min(...packableWrappingSku.map((sku) => sku.minimum_wrapping_size))
    // 包装可能ラッピング資材の中で、包装サイズが最小値のものをfindする
    return packableWrappingSku.find((sku) => sku.minimum_wrapping_size === calcWrappingSize)
  }
}

// 選択されているskuより大きいサイズのギフトがあるか
// const hasLargeGiftByPackageSize = (
//   giftPattern: GiftPatternWithProductFragmentFragment,
//   selectedSku: SkuFragmentFragment | null,
// ): boolean => {
//   if (!selectedSku) return true
//   const giftProduct = giftPattern.product
//   if (!giftProduct || !giftProduct.skus) return false
//   return !!giftProduct.skus.find((sku) => sku.maximum_wrapping_size >= selectedSku.package_size)
// }

const isOutOfStock = (wrappingSku: SkuFragmentFragment) => {
  return (
    wrappingSku.stock_quantity === 0 ||
    wrappingSku.stock_quantity < wrappingSku.stock_quantity_limit + wrappingSku.egift_stock_quantity_limit
  )
}

/**
 * 選択したSKUにラッピング設定可能なギフト資材を取得
 *
 * @param giftPatterns - 商品にラッピング可能なギフトパターンのリスト
 * @param selectedWrappingId - 選択されたラッピング資材ID
 * @param selectedSku - 選択中のSKU
 * @returns SKUにラッピング可能なギフトパターンのリスト
 */
export const getIndividualGiftWrappings = (
  giftPatterns: GiftPatternWithProductFragmentFragment[],
  selectedWrappingId: string | null,
  selectedSku: SkuFragmentFragment | null,
): GiftPatternWithSku[] => {
  const isWrappingGift = (gift: GiftPatternWithProductFragmentFragment) => {
    return (
      (gift.type === "bag" || gift.type === "box") && parentIncludedSelectedGift(gift.parent_ids, selectedWrappingId)
    )
  }

  return giftPatterns.reduce((wrappings: GiftPatternWithSku[], gift) => {
    if (!isWrappingGift(gift)) return wrappings
    const wrappingSku = getCalcWrappingSize(selectedSku, gift)
    if (!wrappingSku) return wrappings

    wrappings.push({
      ...gift,
      wrappingSku,
      isOutOfStock: isOutOfStock(wrappingSku),
    })
    return wrappings
  }, [])
}

export const getIndividualMessages = (
  giftPatterns: GiftPatternWithProductFragmentFragment[],
  selectedWrappingId: string | null,
): GiftPatternWithProductFragmentFragment[] => {
  return giftPatterns.filter((gift) => {
    const isOutOfStockGiftSku = !!getOutOfStockGiftSku(gift)
    return (
      gift.type === "message" &&
      parentIncludedSelectedGift(gift.parent_ids as string[], selectedWrappingId) &&
      !isOutOfStockGiftSku
    )
  })
}

export const getIndividualCards = (
  giftPatterns: GiftPatternWithProductFragmentFragment[],
  selectedCardId: string | null,
): GiftPatternWithSku[] => {
  const cardSkus: GiftPatternWithSku[] = []
  giftPatterns.forEach((gift) => {
    if (gift.type === "card" && parentIncludedSelectedGift(gift.parent_ids as string[], selectedCardId)) {
      const sku = gift.product?.skus?.[0]
      if (!sku) return
      const isOutOfStock =
        sku.stock_quantity === 0 || sku.stock_quantity < sku.stock_quantity_limit + sku.egift_stock_quantity_limit
      cardSkus.push({
        ...gift,
        wrappingSku: sku,
        isOutOfStock,
      })
    }
  })
  return cardSkus
}

export const parentIncludedSelectedGift = (parent_ids: string[], selectedWrapping_id: string | null): boolean => {
  return parent_ids.length === 0 || !!parent_ids.find((parentId) => selectedWrapping_id === parentId)
}
